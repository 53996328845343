
import { Component, Vue } from 'vue-property-decorator';
import { readDevicesList, readIsDev, readOrdersList } from '@/store/main/getters'
import {
  dispatchGetDevices,
  dispatchGetOrders, dispatchIsDev,
  dispatchSubmitUpdateOrder,
} from '@/store/main/actions'
import OrderPaymentModal from '@/components/Main/OrderPaymentModal.vue';
import EditDeliveryAddressModal from '@/components/Main/EditDeliveryAddressModal.vue';
import { IOrder } from '@/interfaces';
import NewOrderModal from '@/components/Main/NewOrderModal.vue';
import ActivateValidatorModal from '@/components/Main/ActivateValidatorModal.vue'
import ActivateValidatorPaymentModal from '@/components/Main/ActivateValidatorPaymentModal.vue'

import { api } from '@/api';
import OrderItem from '@/components/OrderItem.vue'
import DeviceItem from '@/components/DeviceItem.vue'
import Loader from '@/components/Loader.vue'

@Component({
  components: {
    Loader,
    OrderItem,
    DeviceItem,
    OrderPaymentModal,
    EditDeliveryAddressModal,
    NewOrderModal,
    ActivateValidatorModal,
    ActivateValidatorPaymentModal,
  },
})
export default class Dashboard extends Vue {
  public showOrderModal: boolean = false;
  public showEditAddressModal: boolean = false;
  public selectedOrder: IOrder | null = null;
  public showNewOrderModal: boolean = false;
  public blocksProgress: any = null;

  public serialNumber: string = '';

  public showActivateValidatorModal: boolean = false;
  public activateValidatorDeviceId: string = '';

  public showValidatorPaymentModal: boolean = false;
  public validatorPaymentInvoice: any = null;

  public isConnecting:boolean = false;
  public blockNumber: number = 0;
  public feesFor24h: number = 0;

  public handleActivateValidator(deviceId: string) {
    this.activateValidatorDeviceId = deviceId;

    setTimeout(() => {
      this.showActivateValidatorModal = true;
    }, 300);
  }

  public handleValidatorInvoiceCreated(invoice: any) {
    this.closeActivateValidatorModal();

    this.validatorPaymentInvoice = invoice;

    setTimeout(() => {
      this.showValidatorPaymentModal = true;
    }, 300)
  }

  public async closeValidatorPaymentModal() {
    this.showValidatorPaymentModal = false;

    setTimeout(() => {
      this.validatorPaymentInvoice = null;
    }, 300);
  }

  public closeActivateValidatorModal() {
    this.showActivateValidatorModal = false;

    setTimeout(() => {
      this.activateValidatorDeviceId = '';
    }, 300);
  }

  public handleOpenOrderModal(order: IOrder) {
    this.selectedOrder = order;
    this.showOrderModal = true;
  }

  public handleCloseOrderModal() {
    this.selectedOrder = null;
    this.showOrderModal = false;
  }

  public handleOpenEditAddressModal(order: IOrder) {
    this.selectedOrder = order;
    this.showEditAddressModal = true;
  }

  public handleCloseEditAddressModal() {
    this.selectedOrder = null;
    this.showEditAddressModal = false;
  }

  public handleOpenNewOrderModal() {
    this.showNewOrderModal = true;
  }

  public async handleCloseNewOrderModal(id: any = null) {
    this.showNewOrderModal = false;
    if (id) {
      await dispatchGetOrders(this.$store);
      for (let i in this.ordersList) {
        if (this.ordersList[i].id === Number(id)) {
          this.handleOpenOrderModal(this.ordersList[i]);
          return;
        }
      }
    }
  }

  async validateSerial (serial: string, order_id: number) {
    // console.debug('%c validateSerial(serial: %s, order_id: %s)', 'color:gold;', serial, order_id)
    try {
      const data = {
        device_id: serial,
        order: order_id,
      }

      const result = await api.validateSerial(this.$store.state.main.token, data)
      // console.debug('%c Dashboard.vue validateSerial result:', 'color:lime;font-size:36px;', result)
    } catch (err) {
      console.debug('%c Dashboard.vue validateSerial ERROR:', 'color:red;', err)
    }
  }

  public async handleToggleStarlinkKit(order: IOrder) {
    const response = await dispatchSubmitUpdateOrder(
      this.$store, {
        order: order.id,
        starlink_kit: !order.starlink_kit,
      },
    );
    if (response) {
      await dispatchGetOrders(this.$store,);
    } else {
      order.starlink_kit = !order.starlink_kit;
      this.$toast.error(this.$t('Error').toString());
    }
  }

  public async getBlocksProgress() {
   try {
      const response = await api.getBlocksProgress(this.$store.state.main.token)
      this.blocksProgress = response.data
      console.debug('%c Dashboard.vue getBlocksProgress result:', 'color:lime;font-size:36px;')
   } catch (err) {
     console.debug('%c Dashboard.vue getBlocksProgress ERROR:', 'color:red;', err)
   }
  }

  public fetchBlockChainData() {
    Promise.all([
      fetch("https://rpc.dexnetchain.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          method: "eth_blockNumber",
          params: [],
          id: 1,
        }),
      }),
      fetch('https://explorer.dexnetchain.com/api/v2/transactions/stats')
    ])
      .then(([res1, res2]) => {
        return Promise.all([res1.json(), res2.json()])
      })
      .then(([data1, data2]) => {
        this.isConnecting = true;
        this.blockNumber = parseInt(data1.result, 16);
        this.feesFor24h = data2.transaction_fees_sum_24h;

        console.log({
          blockNumber: this.blockNumber,
          feesFor24h: this.feesFor24h,
        })
      })
  }

  get isDev() {
    return readIsDev(this.$store)
  }

  get ordersList() {
    return readOrdersList(this.$store);
  }

  get devicesList() {
    return readDevicesList(this.$store);
  }

  get calculatedBlocksProgress() {
    if (!this.blocksProgress) {
      return 0;
    }

    return 100 * (this.blocksProgress.quantity_in_block - this.blocksProgress.balance) / this.blocksProgress.quantity_in_block
  }

  public async mounted() {
    if ('dev' in this.$route.query && window.location.hostname !== 'dexnet.one') {
      dispatchIsDev(this.$store, true)
    }

    // Загрузить активированные устройства
    await dispatchGetDevices(this.$store);

    // Загрузить заказы
    await dispatchGetOrders(this.$store);

    // Загрузить прогресс блоков
    await this.getBlocksProgress();

    if (this.$router.currentRoute.query.open_order) {
      for (let i in this.ordersList) {
        if (this.ordersList[i].id === Number(this.$router.currentRoute.query.open_order)) {
          this.handleOpenOrderModal(this.ordersList[i]);
          return;
        }
      }
    }

    this.fetchBlockChainData()
  }
}
