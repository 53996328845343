<template>
    <div
        class="country-input"
        v-click-outside="onClickOutside"
        :class="{ 'country-input--active': isSelectOpen }"
    >
        <input
            ref="input"
            class="form__input country-input__input"
            readonly
            :class="{ [errorClass]: isError }"
            :value="selectedCountryTitle"
            :placeholder="$t('Country')"
            @click="toggleSelect"
        >

        <div
            v-if="isSelectOpen"
            class="country-input__country-list"
            :class="`country-input__country-list--${selectListPosition}`"
        >
            <input
                class="country-input__search-input"
                v-model="search"
                :placeholder="$t('Search')"
            >

            <ul id="countries">
                <li
                    v-for="country in filteredCountries"
                    :key="country.iso"
                    :class="{ 'country-input__country-list-option--selected': country.iso === modelValue }"
                    @click="selectCountry(country)"
                >
                    {{ country.flag }} {{ country.translations[currentLocale] }}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: [Object],
        },
        isError: {
            type: Boolean,
            default: false
        },
        errorClass: {
            type: String,
            default: 'form__input--error'
        }
    },
    emits: ['update:modelValue'],
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    data() {
        return {
            countries: [],
            selectedCountry: null,
            isSelectOpen: false,
            search: ''
        }
    },
    computed: {
        currentLocale() {
            return this.$root.$i18n.locale
        },
        selectedCountryOption() {
            return this.countries.find(country => country.iso === this.modelValue?.iso)
        },
        selectedCountryTitle() {
            return this.selectedCountryOption ? `${this.selectedCountryOption.flag} ${this.selectedCountryOption.translations[this.currentLocale]}` : ''
        },
        selectListPosition() {
            return this.$refs.input.getBoundingClientRect().top > 150 ? 'top' : 'bottom'
        },
        filteredCountries() {
            return this.countries.filter(country => {
                return country.translations[this.currentLocale].toLowerCase().includes(this.search.toLowerCase())
            })
        },
    },
    watch: {
        isSelectOpen: {
            handler(isOpen) {
                !isOpen && (this.search = '')
            }
        }
    },
    methods: {
        selectCountry(country) {
            this.isSelectOpen = false
            this.$emit('update:modelValue', { iso: country.iso, country: country.translations[this.currentLocale] })
        },
        toggleSelect() {
            this.isSelectOpen = !this.isSelectOpen
        },
        onClickOutside() {
            this.isSelectOpen = false
        }
    },
    created() {
        fetch('/certificates/countries-list.json')
            .then(response => response.json())
            .then(data => {
                this.countries = data
            })
    }
}
</script>
<style scoped>
.country-input {
    position: relative;
}

.country-input:after {
    content: url(../../assets//images/caret.svg);
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s;
}

.country-input--active:after {
    transform: translateY(-50%) rotate(180deg);
}

.country-input__input {
    cursor: pointer;
}

.country-input__search-input {
    background: rgba(126, 206, 202, 0.1);
    display: block;
    width: 100%;
    border-radius: 10px;
    padding: 12px 13px;
    font-size: 16px;
    /* font-family: var(--main-font); */
    font-weight: 400;
    min-width: 200px;
    color: var(--main-color);
}

.country-input__country-list {
    background: #0e1f1e;
    width: 100%;
    position: absolute;
    left: 0;
    height: 250px;
    border-radius: 10px;
    z-index: 100;
}

.country-input__country-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: calc(100% - 45px);
}

.country-input__country-list ul li {
    display: flex;
    align-items: center;
    padding: 8px;
    outline: none;
    cursor: pointer;
}

.country-input__country-list-option--selected {
    background: rgba(126, 206, 202, 0.5) !important;
}

@media(hover: hover) {
    .country-input__country-list ul li:hover {
        background: rgba(126, 206, 202, 0.4)
    }
}

.country-input__country-list--top {
    top: auto;
    bottom: calc(100% + 8px);
}

.country-input__country-list--bottom {
    top: auto;
    bottom: calc(100% + 8px);
}
</style>
